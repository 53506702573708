.carousel-image {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.carousel-caption h3 {
  font-weight: 300;
}
