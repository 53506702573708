.navbar-dark .navbar-toggler,
.navbar-light .navbar-toggler {
  border: none;
  border: none;

  &:focus {
    outline: none;
  }
}


.navbar-dark .navbar-nav .nav-link {
  color:rgb(240, 238, 238);
}