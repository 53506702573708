footer {
  padding: 1em 0;
}

footer .col-md-4 {
  padding-top: 0.7em;
  padding-bottom: 0.7em;
}

footer h4 {
  margin-bottom: 1em;
  text-align: center;
}

footer a.h5 {
  color: black;
}

footer ul {
  margin: 0;
  padding: 0;
}

footer ul li {
  list-style: none;
}

footer ul li a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
}

footer a.h5:hover {
  color: #6fbfbe;
  text-decoration: none;
}

footer ul li a:hover {
  color: #6fbfbe;
  text-decoration: none;
}

footer svg {
  color: #6fbfbe;
}
