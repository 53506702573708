.whatsapp-chat {
  position: fixed;
  bottom: 45px;
  right: 25px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.whatsapp-chat span {
  margin: 0 10px 50px 0;
  padding: 0.3em .7em;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px 20px 0px 8px;
  align-self: flex-start;
  opacity: 0;
}

.whatsapp-chat span.show {
  animation: fade-in 2s forwards;
}

.whatsapp-chat span.hidden {
  display: none;
  animation: fade-out 2s forwards;
}

.whatsapp-chat p {
  margin: 0px;
}

.whatsapp-button {
  padding: 0.5em 0.7em;
  background-color: #25d366;
  border: solid 1px #25d366;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  align-self: flex-end;
  animation: pulse 2s infinite;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(37, 211, 102, 0.2);
  }

  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}
